import gsap from './gsap';
function animate(container) {
    const q = gsap.utils.selector(container);
    const lines = q('.js-heading-line');
    const intro = q('.js-intro');
    const timeline = gsap.timeline({
        delay: 0.25,
        scrollTrigger: {
            trigger: container,
            start: 'top center',
            end: 'bottom center',
        },
    });
    gsap.set(lines, {
        opacity: 0,
        y: '50%',
        transformOrigin: '50% 50%',
    });
    gsap.set(intro, {
        opacity: 0,
        scale: 0,
        transformOrigin: '50% 50%',
    });
    timeline.to(lines, {
        opacity: 1,
        y: 0,
        ease: 'back.out',
        duration: 0.333,
        stagger: 0.2,
    });
    timeline.to(intro, {
        opacity: 1,
        scale: 1,
        ease: 'back.out',
        duration: 0.333,
    });
}
animate('#history');
