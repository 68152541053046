import gsap from './gsap';
function animateHeading(container) {
    const q = gsap.utils.selector(container);
    const heading = q('.js-heading');
    const image = q('.js-image');
    gsap.set(heading, {
        y: '75%',
        opacity: 0,
    });
    gsap.set(image, {
        y: '-30%',
        opacity: 0,
    });
    gsap.to([...heading, ...image], {
        y: 0,
        opacity: 1,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
        },
    });
}
animateHeading('#pork-pies-range');
animateHeading('#pickle-pies-range');
animateHeading('#snacking-range');
