export const ranges = {
    'pork-pies': {
        name: 'Pork Pies',
        products: [{
                name: 'Large Original Pie',
                retailers: [],
                impulse: false,
            }, {
                name: 'Medium Original Pie',
                retailers: ['asda', 'tesco'],
                impulse: true,
            }, {
                name: 'Medium Melton Pie',
                retailers: ['sainsburys'],
                impulse: false,
            }, {
                name: 'Individual Original Pie',
                retailers: ['asda', 'sainsburys', 'tesco'],
                impulse: true,
            }, {
                name: '4pk Snack Pork Pies',
                retailers: ['asda'],
                impulse: false,
            }, {
                name: '4pk Mini Pork Pies',
                retailers: ['tesco'],
                impulse: true,
            }, {
                name: '4pk Branston Topped Pork Pies',
                retailers: ['asda', 'tesco'],
                impulse: true,
            }, {
                name: '2pk Snack Pork Pies',
                retailers: [],
                impulse: true,
            }, {
                name: '2pk Pork + Pickle Pies',
                retailers: ['sainsburys'],
                impulse: true,
            }, {
                name: '2pk Vegan Porkless Pies',
                retailers: ['asda'],
                impulse: false,
            }, {
                name: 'Hog Roast Medium',
                retailers: [],
                impulse: false,
            }],
    },
    'pickle-pies': {
        name: 'Pickle Pies',
        products: [],
    },
    'snacking': {
        name: 'Snacking',
        products: [{
                name: 'Cocktail Sausages',
                impulse: true,
            }],
    },
};
